import { FunctionComponent } from 'react';

import { Price as PriceAtom } from '@/atoms';

import { TypePriceProps } from './types';

export const Price: FunctionComponent<TypePriceProps> = ({
  className = '',
  regular,
  sale,
  position = 'inline',
  justify,
  align,
}: TypePriceProps): JSX.Element => (
  <PriceAtom
    position={position}
    regular={regular}
    sale={sale}
    className={className}
    justify={justify}
    align={align}
  />
);
