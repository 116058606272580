import { FunctionComponent } from 'react';
import { oneLine } from 'common-tags';
import Numeral from 'numeral';

import { TypePriceProps } from './types';

export const Price: FunctionComponent<TypePriceProps> = ({
  className = '',
  regular,
  sale,
  position,
  size = 'small',
}: TypePriceProps) => {
  if (Numeral.locales['es-es'] === undefined) {
    Numeral.register('locale', 'es-es', {
      delimiters: {
        thousands: '.',
        decimal: ',',
      },
      abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
      },
      ordinal: function (number) {
        return number === 1 ? 'er' : 'ème';
      },
      currency: {
        symbol: '€',
      },
    });
  }

  Numeral.locale('es-ES');
  return (
    <div
      className={oneLine`
      inline-flex tracking-[0.04rem]
      ${
        position === 'below'
          ? 'flex-col'
          : 'flex-row-reverse gap-2 items-baseline'
      }
      ${className}
    `}
    >
      {sale && sale !== regular && (
        <p
          className={oneLine`
          text-secondary-600 line-through
          ${
            size === 'small'
              ? 'text-price text-price--s'
              : 'text-body text-body--m'
          }
        `}
        >
          {regular}
        </p>
      )}

      <p
        className={oneLine`
        text-text-primary text-price
        ${size === 'small' ? 'text-price--m' : 'text-price--xl'}
        ${position === 'below' ? '' : 'md:text-price--l'}
      `}
      >
        {sale
          ? Numeral(parseFloat(sale)).format('1,000.00 $')
          : Numeral(parseFloat(regular)).format('1,000.00 $')}
      </p>
    </div>
  );
};

Price.displayName = 'Price';
