import { FunctionComponent } from 'react';

import { TypeTagProps } from './types';

export const Tag: FunctionComponent<TypeTagProps> = ({
  className = '',
  colors = 'info',
  children,
  Icon,
}: TypeTagProps) => (
  <span
    className={`
      flex gap-1 text-caption text-caption--m items-center max-w-max h-8 p-3
      ${colors === 'info' ? 'bg-primary-200 text-text-tertiary' : ''}
      ${colors === 'promo' ? 'bg-tertiary-100 text-tertiary-500' : ''}
      ${className}
    `}
  >
    {Icon && <Icon className="w-6 h-6" />}
    {children}
  </span>
);

Tag.displayName = 'Tag';
