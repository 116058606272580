import { FunctionComponent, ReactNode } from 'react';
import { ArrowLeft, ArrowRight } from '@/icons';
import { Button } from '@/atoms';
import { ButtonVariant } from '@/atoms/button/types';
import { toArray } from '@/lib/utils';
import { Options, Splide as TypeSplide } from '@splidejs/splide';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { Grid } from '@splidejs/splide-extension-grid';
import { StylePagination } from './types';
import { TypeSliderProductProps } from '.';

import '@splidejs/react-splide/css/core';

const STYLE_MAPS: Record<StylePagination, string> = {
  [StylePagination.PAGINATION]: `flex md:hidden relative flex-nowrap justify-center md:justify-end gap-3 [&:has(li:only-child)]:hidden`,
  [StylePagination.PAGE]: `before:w-1 before:h-1 p-3 relative before:bottom-2.5 before:right-2.5 before:rotate-45 before:bg-primary-500 before:content:[''] before:absolute [&.is-active]:after:border-primary-500 [&.is-active]:after:content-[''] [&.is-active]:after:border [&.is-active]:after:rounded-full [&.is-active]:after:p-3 [&.is-active]:after:absolute [&.is-active]:after:top-1/2 [&.is-active]:after:left-1/2 [&.is-active]:after:transform [&.is-active]:after:-translate-y-1/2 [&.is-active]:after:-translate-x-1/2`,
};

export const SliderProduct: FunctionComponent<TypeSliderProductProps> = ({
  className = '',
  height,
  breakpoints,
  perPage,
  children,
  type,
  gap = 24,
  extensions = ['none'],
  grid,
}: TypeSliderProductProps) => {
  const childrenArray = toArray(children);
  const DEFUALT_PADDING = '15px';
  const adjustPadding = (
    splide: TypeSplide,
    track: HTMLElement,
    rootParent: HTMLElement | null,
    start: number,
    last: number,
    padding: Options['padding']
  ) => {
    if (padding) {
      if (start === splide.index) {
        track.style.paddingLeft = '0';
        if (rootParent) rootParent.style.paddingLeft = DEFUALT_PADDING;
      } else {
        track.style.paddingLeft = `${padding}px`;
        if (rootParent) rootParent.style.paddingLeft = '0';
      }

      if (last === splide.index) {
        track.style.paddingRight = '0';
        if (rootParent) rootParent.style.paddingRight = DEFUALT_PADDING;
      } else {
        track.style.paddingRight = `${padding}px`;
        if (rootParent) rootParent.style.paddingRight = '0';
      }
    } else {
      track.style.paddingLeft = '0';
      track.style.paddingRight = '0';

      if (rootParent) rootParent.style.paddingLeft = '0';
      if (rootParent) rootParent.style.paddingRight = '0';
    }
  };
  const adjustAllPaddings = (splide: TypeSplide) => {
    const last = splide.Components.Controller.getEnd();
    const start = 0;
    const padding = splide.options.padding;
    const track = splide.Components.Elements.track;
    const root = splide.Components.Elements.root;
    const rootParent = root.parentElement;
    const breakpoints = splide.options.breakpoints;

    if (breakpoints) {
      Object.entries(breakpoints).forEach(([key, value], index) => {
        if (
          window.screen.width <= parseInt(key) &&
          ((index > 0 &&
            window.screen.width >
            parseInt(Object.keys(breakpoints)[index - 1])) ||
            index === 0)
        ) {
          adjustPadding(splide, track, rootParent, start, last, value.padding);
        }
      });
    }

    adjustPadding(splide, track, rootParent, start, last, padding);
  };

  const options: Options = {
    ...(type && { type }),
    perPage: perPage,
    ...(breakpoints && { breakpoints }),
    ...(height && { height }),
    ...(gap && { gap }),
    perMove: 1,
    pagination: true,
    arrows: true,
    lazyLoad: true,
    ...(extensions.includes('grid') ? { grid: grid } : { gap: gap }),
    classes: {
      pagination: STYLE_MAPS[StylePagination.PAGINATION],
      page: STYLE_MAPS[StylePagination.PAGE],
      arrows: 'order-3',
      prev: 'rotate-180',
    },
  };

  /**
   * Render slides.
   *
   * @return Slide nodes.
   */
  const renderSlides = (): ReactNode => (
    <SplideTrack>
      {childrenArray.map((child, index) => (
        <SplideSlide key={index}>{child}</SplideSlide>
      ))}
    </SplideTrack>
  );

  return (
    <Splide
      options={options}
      className={`-mx-4 md:mx-0 flex flex-col gap-6 ${className}`}
      onMove={(splide) => adjustAllPaddings(splide)}
      onMounted={(splide) => adjustAllPaddings(splide)}
      hasTrack={false}
      {...(extensions.includes('grid') && { extensions: { Grid } })}
    >
      {renderSlides()}

      <div className="splide__arrows self-end w-max flex-row gap-4 hidden md:hidden md:[&:has(button:enabled)]:inline-flex">
        <Button
          variant={ButtonVariant.ICON}
          outline
          className="splide__arrow splide__arrow--prev"
        >
          <ArrowLeft width={16} height={16} />
        </Button>

        <Button
          variant={ButtonVariant.ICON}
          outline
          className="splide__arrow splide__arrow--next"
        >
          <ArrowRight width={16} height={16} />
        </Button>
      </div>
    </Splide>
  );
};

SliderProduct.displayName = 'SliderProduct';
