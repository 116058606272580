import { Fragment, FunctionComponent } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { TypeModalProps } from './types';
import { Close } from '@/icons';

export const Modal: FunctionComponent<TypeModalProps> = ({
  isButtonX = true,
  children,
  isOpen = true,
  closeModal,
  className = '',
}: TypeModalProps) => (
  <Transition appear show={isOpen} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={closeModal}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black bg-opacity-25" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel
              className={`w-full p-10 transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all relative ${className}`}
            >
              {isButtonX && (
                <div className="flex justify-end absolute right-10 top-10 -translate-y-1/2">
                  <button type="button" onClick={closeModal}>
                    <Close width={24} height={24} />
                  </button>
                </div>
              )}
              <div className="text-base text-gray-500">{children}</div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
);

Modal.displayName = 'Modal';
