import { CardProduct } from './CardProduct';

import * as childs from './childs';

export type TypeCardProduct = typeof CardProduct & {
  Title: typeof childs.Title;
  Price: typeof childs.Price;
  Tag: typeof childs.Tag;
};

(CardProduct as TypeCardProduct).Title = childs.Title;
(CardProduct as TypeCardProduct).Price = childs.Price;
(CardProduct as TypeCardProduct).Tag = childs.Tag;

childs.Title.displayName = 'CardProduct.Title';
childs.Price.displayName = 'CardProduct.Price';
childs.Tag.displayName = 'CardProduct.Tag';

export type { TypeCardProductProps } from './types';
export type {
  TypeTextChildProps,
  TypePriceProps,
  TypeTagProps,
} from './childs/types';

export default CardProduct as TypeCardProduct;
