import { ReactNode } from 'react';
import { TypeClassName, TypeImage } from '@/types';
import { DrupalNode } from 'next-drupal';

export const enum Size {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}
export const enum Variation {
  DEFAULT = 'DEFAULT',
  DISPLAY = 'DISPLAY',
}

interface TypeCommonProps extends TypeClassName {
  /**
   * Indica el tipo de Card a mostrar
   */
  size: Size;
  /**
   * Indica el tipo de color a mostrar
   */
  variation: Variation;
}

export type TypeConditionalProps =
  | {
      /**
       * Imagen del card
       */
      image: TypeImage;
      /**
       * Enlace al producto
       */
      href: string;
      /**
       * ¿Tiene el producto stock?
       */
      inStock?: boolean;
      /**
       * Permite pasar los subcomententes CardProduct.Price,
       * CardProduct.Tag y CardProduct.Title
       */
      children: ReactNode | ReactNode[];
      /**
       * Index del producto dentro de la lista
       */
      itemListIndex: string | number;
      /**
       * Nombre de la lista
       */
      itemListName: string;
             /**
       * Listado de products
       */
              product: DrupalNode;
    }
  | {
      /**
       * Imagen del card
       */
      image?: never;
      /**
       * Enlace al producto
       */
      href?: never;
      /**
       * ¿Tiene el producto stock?
       */
      inStock?: never;
      /**
       * Permite pasar los subcomententes CardProduct.Price,
       * CardProduct.Tag y CardProduct.Title
       */
      children?: never;
      /**
       * Index del producto dentro de la lista
       */
      itemListIndex?: never;
      /**
       * Nombre de la lista
       */
      itemListName?: never;
       /**
       * Listado de products
       */
       product: DrupalNode;
    };

export type TypeCardProductProps = TypeCommonProps & TypeConditionalProps;
