import { FunctionComponent } from 'react';

import { Tag as TagAtom } from '@/atoms';

import { TypeTagProps } from './types';

export const Tag: FunctionComponent<TypeTagProps> = ({
  children,
  Icon,
  colors,
  className = '',
}: TypeTagProps): JSX.Element => (
  <TagAtom Icon={Icon} colors={colors} className={className}>
    {children}
  </TagAtom>
);
