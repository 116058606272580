import { FunctionComponent } from 'react';
import { Link } from '@/atoms';
import { LinkVariant } from '@/atoms/link/types';
import { TypeProductBlockProps } from './types';

export const ProductBlock: FunctionComponent<TypeProductBlockProps> = ({
  className = '',
  title,
  textlink,
  href,
  children,
}: TypeProductBlockProps) => (
  <div
    className={`flex flex-col gap-6 md:gap-12 justify-center md:justify-start ${className}`}
  >
    <div className="flex flex-col md:flex-row justify-between md:items-baseline items-center">
      <h2
        className="text-headline text-headline--h2 text-text-primary md:text-left text-center"
        id={title?.split(' ').join('_')}
      >
        {title}
      </h2>

      {textlink && href ? (
        <Link
          variant={LinkVariant.POSITIVE}
          icon="right"
          href={href}
          className="text-center md:text-left hidden md:inline-flex"
        >
          {textlink}
        </Link>
      ) : null}
    </div>
    <div>{children}</div>

    {textlink && href ? (
      <Link
        variant={LinkVariant.POSITIVE}
        icon="right"
        href={href}
        className="text-center md:text-left md:hidden mt-7 self-center"
      >
        {textlink}
      </Link>
    ) : null}
  </div>
);

ProductBlock.displayName = 'ProductBlock';
