import { FunctionComponent, useEffect, useState } from 'react';
import Image from 'next/image';
import { oneLine } from 'common-tags';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';

import { Link as Enlace } from '@/atoms';

import * as childs from './childs';
import { Bag } from '@/icons';
import { Button, Modal, Tag } from '@/atoms';

import {
    getUrlExtension,
    isPNG,
    ordenarEtiquetas,
    pickChild,
    priceToNumber,
} from '@/lib/utils';
import { addToCart } from '@/lib/carts';
import { setTipoProducto } from '@/lib/typeElements';

import FallBackIMG from '@/assets/images/fallback-product-card.jpg';

import { ButtonVariant } from '@/atoms/button/types';
import { Size, TypeCardProductProps, Variation } from './types';
import { RootState } from '@/lib/store';
import { gtmPush } from '@/utils';

const SIZE_CARD: Record<Size, string> = {
    [Size.SMALL]: 'h-full',
    [Size.MEDIUM]: 'flex flex-col justify-end',
    [Size.LARGE]: 'flex flex-col',
};

const SIZE_IMAGE: Record<Size, string> = {
    [Size.SMALL]: 'w-full h-[180px]',
    [Size.MEDIUM]: 'w-full h-[242px]',
    [Size.LARGE]: 'w-[190px] h-[242px]',
};

const SIZE_TITLE: Record<Size, string> = {
    [Size.SMALL]: 'text-body text-body--m line-clamp-2',
    [Size.MEDIUM]: 'text-body text-body--l line-clamp-2',
    [Size.LARGE]: 'text-subtitle text-subtitle--s line-clamp-3',
};

const VARIATIONS_MAPS: Record<Variation, string> = {
    [Variation.DEFAULT]: 'bg-white',
    [Variation.DISPLAY]: 'bg-primary-50',
};

/**
 * CardProduct
 */
export const CardProduct: FunctionComponent<TypeCardProductProps> = ({
    image,
    size = Size.MEDIUM,
    variation = Variation.DEFAULT,
    children,
    className = '',
    href,
    itemListIndex,
    itemListName,
    inStock = true,
    product,
}: TypeCardProductProps): JSX.Element => {
    const dispatch = useDispatch();
    const [, tag] = pickChild(children, childs.Tag);
    const [, title] = pickChild(children, childs.Title);
    const [, price] = pickChild(children, childs.Price);
    const srcWithFallback =
        image?.src && image?.src !== '' ? image.src : FallBackIMG.src;
    const positionPrice = size === Size.SMALL ? 'below' : 'inline';
    const percentage = Math.floor(
        (1 -
            priceToNumber(price?.props?.sale) /
            priceToNumber(price?.props?.regular)) *
        100
    );
    const hasSale =
        price?.props?.sale && price?.props?.sale !== price?.props?.regular;
    const tipoSelección = useSelector((state: RootState) => state.typeElements);

    const productWithGTM = {
        ...product,
        index: itemListIndex,
        ['item_list_name']: itemListName,
    };

    const [isModal, setIsModal] = useState(false);

    const giftOrNormal = () => product?.metafields?.custom?.gift?.value === true ? 'gift' : 'normal';

    const activaTipoProducto = () => {
        dispatch(setTipoProducto(giftOrNormal()));
        sessionStorage.setItem('typeElements', giftOrNormal());
    };

    useEffect(() => {
        dispatch(setTipoProducto(sessionStorage.getItem('typeElements') || ''));
    }, []);

    const carts = useSelector((state: RootState) => state.carts);

    return (
        <div
            className={`h-full ${SIZE_CARD[size]} ${VARIATIONS_MAPS[variation]} ${className}`}
        >
            {(tag || !inStock || hasSale) && (
                <div className="flex gap-1 px-2 pt-2">
                    {tag}
                    {!inStock && <Tag colors="promo">Sin stock</Tag>}
                    {hasSale && <Tag colors="promo">{`-${percentage}%`}</Tag>}
                </div>
            )}

            <div
                className={
                    size === 'LARGE' ? 'flex' : 'flex-grow flex flex-col justify-end'
                }
            >
                {(
                    href && (
                        <Link href={href} prefetch={false}>
                            <div
                                className={`relative mt-2 ${SIZE_IMAGE[size]}`}
                                onClick={() => {
                                    gtmPush({ ecommerce: null });
                                    const tags = product.tags.map((item) => (item.name));
                                    gtmPush({
                                        event: 'select_item',
                                        ecommerce: {
                                            items: [
                                                {
                                                    item_id: product.product_id,
                                                    item_name: product.title.toUpperCase(),
                                                    affiliation: 'Navidul',
                                                    coupon: '',
                                                    currency: 'EUR',
                                                    item_brand: 'Navidul',
                                                    item_category: ordenarEtiquetas(tags)[0][0]
                                                        ? ordenarEtiquetas(tags)[0][0]
                                                        : '',
                                                    item_category2: ordenarEtiquetas(tags)[0][1]
                                                        ? ordenarEtiquetas(tags)[0][1]
                                                        : '',
                                                    item_category3: ordenarEtiquetas(tags)[0][2]
                                                        ? ordenarEtiquetas(tags)[0][2]
                                                        : '',
                                                    price: product.variants[0].price,
                                                },
                                            ],
                                        },
                                    });
                                }}
                            >

                                <Image
                                    src={image.src}
                                    alt={image.alt ?? 'Card image'}
                                    className={oneLine`
                      w-full h-full object-center absolute inset-0
                      ${isPNG(getUrlExtension(srcWithFallback))
                                            ? 'object-contain'
                                            : 'object-cover'
                                        }
                    `}
                                    draggable="false"
                                    sizes="(max-width: 500px) 100vw,
                (max-width: 768px) 50vw,
                (max-width: 1024px) 33vw,
                25vw"
                                    fill
                                />
                            </div>
                        </Link>
                    )
                )}
                <div
                    className={oneLine`
            flex flex-col px-3 pb-3
            ${Size.SMALL === size ? 'gap-0' : 'gap-3'}
            ${Size.LARGE === size ? 'flex-col mt-6 justify-center' : ''}
          `}
                >
                    {(
                        href && (
                            <h3 className={`font-semibold max-w-[200px] ${SIZE_TITLE[size]}`}>
                                <Link href={href} prefetch={false}>
                                    {title}
                                </Link>
                            </h3>
                        )
                    )}

                    <div
                        className={
                            size === Size.SMALL || size === Size.MEDIUM
                                ? 'mt-auto flex gap-4 items-center justify-between'
                                : ''
                        }
                    >
                        {price && (
                            <price.type position={positionPrice} {...price.props} />
                        )}
                        {size === Size.LARGE ? (
                            <Button
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                onClick={() => dispatch(addToCart(productWithGTM, 1))}
                                variant={ButtonVariant.PRIMARY}
                                className="mt-10 !w-full"
                                aria-label="Añadir al carrito"
                                disabled={!inStock}
                            >
                                Añadir
                            </Button>
                        ) : (
                            <Button
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                onClick={() => {
                                    if (
                                        giftOrNormal() === tipoSelección.value ||
                                        tipoSelección.value === ''
                                    ) {
                                        activaTipoProducto();
                                        if (
                                            giftOrNormal() === 'gift' &&
                                            carts.data.cart.lines.edges.length + 1 > 1
                                        ) {
                                            setIsModal(true);
                                        } else {
                                            dispatch(addToCart(productWithGTM, 1));
                                        }
                                    } else {
                                        setIsModal(true);
                                    }
                                }}
                                variant={ButtonVariant.ICON}
                                aria-label="Añadir al carrito"
                                disabled={!inStock}
                            >
                                {tipoSelección.value === 'gift' && (
                                    <Modal
                                        isOpen={isModal}
                                        closeModal={() => setIsModal(false)}
                                        className="w-6/12"
                                    >
                                        <div className="flex flex-col md:flex-column gap-20 md:gap-10">
                                            <div className="flex flex-col gap-6 md:gap-4">
                                                <span className="font-bold text-body text-body--l text-text-primary">
                                                    No podemos agregar el producto
                                                </span>
                                                <span className="text-body text-body--m text-text-primary">
                                                    Tienes un producto de regalo añadido a tu carrito, por
                                                    lo que no podemos añadir otro producto adicional. Para
                                                    añadir un producto diferente a tu pedido, vacía
                                                    primero tu carrito.
                                                </span>
                                            </div>
                                            <div className="flex flex-col gap-5" />
                                            <Enlace
                                                className="justify-center md:justify-start"
                                                icon="right"
                                                variant="POSITIVE"
                                                href="/es/cesta"
                                            >
                                                Ir a la cesta
                                            </Enlace>
                                        </div>
                                    </Modal>
                                )}

                                {tipoSelección.value === 'gift' &&
                                    carts.data.cart.lines.edges.length > 1 && (
                                        <Modal
                                            isOpen={isModal}
                                            closeModal={() => setIsModal(false)}
                                            className="w-6/12"
                                        >
                                            <div className="flex flex-col md:flex-column gap-20 md:gap-10">
                                                <div className="flex flex-col gap-6 md:gap-4">
                                                    <span className="font-bold text-body text-body--l text-text-primary">
                                                        No podemos agregar el producto
                                                    </span>
                                                    <span className="text-body text-body--m text-text-primary">
                                                        Tienes un producto de regalo añadido a tu carrito,
                                                        por lo que no podemos añadir otro producto
                                                        adicional. Para añadir un producto diferente a tu
                                                        pedido, vacía primero tu carrito.
                                                    </span>
                                                </div>
                                                <div className="flex flex-col gap-5" />
                                                <Enlace
                                                    className="justify-center md:justify-start"
                                                    icon="right"
                                                    variant="POSITIVE"
                                                    href="/es/cesta"
                                                >
                                                    Ir a la cesta
                                                </Enlace>
                                            </div>
                                        </Modal>
                                    )}

                                {tipoSelección.value === 'normal' && (
                                    <Modal
                                        isOpen={isModal}
                                        closeModal={() => setIsModal(false)}
                                        className="w-6/12"
                                    >
                                        <div className="flex flex-col md:flex-column gap-20 md:gap-10">
                                            <div className="flex flex-col gap-6 md:gap-4">
                                                <span className="font-bold text-body text-body--l text-text-primary">
                                                    No podemos agregar el producto
                                                </span>
                                                <span className="text-body text-body--m text-text-primary">
                                                    Ya tienes un producto añadido a tu carrito, por lo que
                                                    no podemos añadir un producto de regalo. Para añadir
                                                    un producto de regalo, vacía primero tu carrito.
                                                </span>
                                            </div>
                                            <div className="flex flex-col gap-5" />
                                            <Enlace
                                                className="justify-center md:justify-start"
                                                icon="right"
                                                variant="POSITIVE"
                                                href="/es/cesta"
                                            >
                                                Vaciar carrito
                                            </Enlace>
                                        </div>
                                    </Modal>
                                )}

                                <Bag height={24} width={24} />
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

CardProduct.displayName = 'CardProduct';
