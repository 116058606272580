import { IconType, defaultProps } from './types';

/**
 * Icono Close
 */
export const Close = ({
  className,
  width,
  height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className ? { className: className } : {})}
    {...(width ? { width: width } : {})}
    {...(height ? { height: height } : {})}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M4.176 4.176a.601.601 0 0 1 .85 0L12 11.15l6.974-6.974a.601.601 0 1 1 .85.85L12.85 12l6.974 6.974a.601.601 0 1 1-.85.85L12 12.85l-6.974 6.974a.601.601 0 1 1-.85-.85L11.15 12 4.176 5.026a.601.601 0 0 1 0-.85Z"
      fill={color}
    />
  </svg>
);
