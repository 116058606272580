import { IconType, defaultProps } from './types';

/**
 * Icono ArrowLeft
 */
export const ArrowLeft = ({
  className,
  width,
  height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className ? { className: className } : {})}
    {...(width ? { width: width } : {})}
    {...(height ? { height: height } : {})}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.552 2 0 11.552l9.552 9.553 1.154-1.154-7.582-7.583H24v-1.632H3.124l7.582-7.582L9.552 2Z"
      fill={color}
    />
  </svg>
);
